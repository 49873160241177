import { extensionNotificationsMutations } from './types'

export default {
  [extensionNotificationsMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [extensionNotificationsMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [extensionNotificationsMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [extensionNotificationsMutations.PUSH_LIST] (state, value) {
    state.list = state.list.concat(value)
  },

  [extensionNotificationsMutations.UNSHIFT_LIST] (state, value) {
    state.list.splice(0, 0, value)
  },

  [extensionNotificationsMutations.SET_FETCH_NEXT] (state, value) {
    state.fetchNext = value
  },
}
