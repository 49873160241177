import { extensionNotificationsGetters } from './types'
import { Notification } from 'Models/Notification'

export default {
  [extensionNotificationsGetters.IS_LOADING]: state => state.isLoading,
  [extensionNotificationsGetters.IS_NEXT_LOADING]: state => state.isNextLoading,
  [extensionNotificationsGetters.FETCH_NEXT]: state => state.fetchNext,
  [extensionNotificationsGetters.LIST]: state => state.list
    .map(item => new Notification(item)),
}
