import { NOTIFICATIONS_QUERY_SORTS } from 'Constants/notificationsUrlParams'

export const NOTIFICATIONS_PAGE_LIMIT = 15

export const NOTIFICATIONS_DEFAULT_SORT =
  NOTIFICATIONS_QUERY_SORTS.createdAtDesc
export const NOTIFICATIONS_SORT_TO_ORDER_MAP = Object.freeze({
  [NOTIFICATIONS_QUERY_SORTS.createdAt]: 'asc',
  [NOTIFICATIONS_QUERY_SORTS.createdAtDesc]: 'desc',
})
