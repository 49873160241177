import {
  extensionNotificationsActions,
  extensionNotificationsMutations,
  extensionNotificationsGetters
} from './types'
import { NOTIFICATIONS_PAGE_LIMIT } from '../constants'

import { rootDispatch } from 'Store/helpers/rootHelpers'
import { bellActions } from 'Store/entities/Bell/types'

import { sdk } from 'Services/shelfNetworkSdk'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

import omitBy from 'lodash/omitBy'

const loneSdkCall = new LoneSdkCall()

export default {
  async [extensionNotificationsActions.LOAD_NOTIFICATIONS] (
    { commit },
    payload = {}
  ) {
    commit(extensionNotificationsMutations.SET_IS_LOADING, true)
    payload = normalizePayload(payload)

    const { data, fetchNext } =
      await loneSdkCall.takeLatest(sdk.ringer.getAll(payload))

    commit(extensionNotificationsMutations.SET_LIST, data)
    commit(extensionNotificationsMutations.SET_FETCH_NEXT, fetchNext)

    await rootDispatch(`entities/bell/${bellActions.MARK_READ}`, {
      topics: payload.filter.topic.split(',')
    })
    commit(extensionNotificationsMutations.SET_IS_LOADING, false)
  },

  async [extensionNotificationsActions.LOAD_MORE] ({ commit, getters }) {
    const fetchNext = getters[extensionNotificationsGetters.FETCH_NEXT]
    if (!fetchNext) return

    commit(extensionNotificationsMutations.SET_IS_NEXT_LOADING, true)

    const { data, fetchNext: newFetchNext } =
      await loneSdkCall.takeLatest(fetchNext())

    commit(extensionNotificationsMutations.PUSH_LIST, data)
    commit(extensionNotificationsMutations.SET_FETCH_NEXT, newFetchNext)

    commit(extensionNotificationsMutations.SET_IS_NEXT_LOADING, false)
  },

  [extensionNotificationsActions.UNSHIFT_NOTIFICATIONS] ({ commit }, item) {
    commit(extensionNotificationsMutations.UNSHIFT_LIST, item)
  }
}

function normalizePayload (payload) {
  const result = Object.assign({}, payload)
  result.page = Object.assign({}, payload.page)

  result.page.limit = NOTIFICATIONS_PAGE_LIMIT

  return omitBy(result, val => !val)
}
